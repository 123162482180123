import { ifAuthenticated, ifNotAuthenticated } from '@/router/helpers';
import Login from '@/views/Login';
import Index from '@/views/Manage/index';
import i18n from '@/i18n';
import Dashboard from '@/views/Manage/Dashboard';
import Staff from '@/views/Manage/Staff';
import Categories from '@/views/Manage/Categories';
import Brands from '@/views/Manage/Brands';
import Content from '@/views/Manage/Content';
import Parts from '@/views/Manage/Parts';
import Banners from '@/views/Manage/Banners';
import Polls from '@/views/Manage/Polls';
import Types from '@/views/Manage/Types';
import News from '@/views/Manage/News';
import NewsForm from '@/views/Manage/NewsForm';
import Galleries from '@/views/Manage/Galleries';
import Contributions from '@/views/Manage/Settings/Contributions';
import Pages from '@/views/Manage/Settings/Pages';
import Socials from '@/views/Manage/Settings/Socials';
import Plans from '@/views/Manage/Settings/Plans';
import Customers from '@/views/Manage/Customers/Customers';

export default [
  {
    path: '/',
    name: 'Home',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/manage',
    component: Index,
    meta: {
      title: 'Manage',
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: i18n.t('pageTitle.dashboard'),
        },
      },
      {
        path: '',
        name: 'Staff',
        component: Staff,
        meta: {
          title: i18n.t('pageTitle.staff'),
        },
      },
      {
        path: 'banners',
        name: 'Banners',
        component: Banners,
        meta: {
          title: i18n.t('pageTitle.banners'),
        },
      },
      {
        path: 'categories',
        name: 'Categories',
        component: Categories,
        meta: {
          title: i18n.t('pageTitle.categories'),
        },
      },
      {
        path: 'brands',
        name: 'Brands',
        component: Brands,
        meta: {
          title: i18n.t('pageTitle.brands'),
        },
      },
      {
        path: 'content',
        name: 'Content',
        component: Content,
        meta: {
          title: 'Бүтээгдэхүүн',
        },
      },
      {
        path: 'parts',
        name: 'Parts',
        component: Parts,
        meta: {
          title: 'Сэлбэг',
        },
      },
      {
        path: 'types',
        name: 'Types',
        component: Types,
        meta: {
          title: i18n.t('pageTitle.types'),
        },
      },
      {
        path: 'polls',
        name: 'Polls',
        component: Polls,
        meta: {
          title: i18n.t('pageTitle.polls'),
        },
      },
      {
        path: 'news',
        name: 'News',
        component: News,
        meta: {
          title: i18n.t('pageTitle.news'),
        },
      },
      {
        path: 'add-news',
        name: 'NewsAdd',
        component: NewsForm,
        meta: {
          title: i18n.t('pageTitle.newsAdd'),
        },
      },
      {
        path: 'edit-news/:id',
        name: 'NewsEdit',
        component: NewsForm,
        meta: {
          title: i18n.t('pageTitle.newsEdit'),
        },
      },
      {
        path: 'galleries',
        name: 'Galleries',
        component: Galleries,
        meta: {
          title: i18n.t('pageTitle.galleries'),
        },
      },
      {
        path: 'socials',
        name: 'Socials',
        component: Socials,
        meta: {
          title: i18n.t('pageTitle.socials'),
        },
      },
      {
        path: 'contributions',
        name: 'Contributions',
        component: Contributions,
        meta: {
          title: i18n.t('pageTitle.contributions'),
        },
      },
      {
        path: 'pages',
        name: 'Pages',
        component: Pages,
        meta: {
          title: i18n.t('pageTitle.pages'),
        },
      },
      {
        path: 'plans',
        name: 'Plans',
        component: Plans,
        meta: {
          title: i18n.t('pageTitle.plans'),
        },
      },
      {
        path: 'customers',
        name: 'Customers',
        component: Customers,
        meta: {
          title: i18n.t('pageTitle.customers'),
        },
      },
    ],
    beforeEnter: ifAuthenticated,
  },
];
