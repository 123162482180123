import UsersRepository from '@/repositories/UsersRepository';
import CategoriesRepository from '@/repositories/CategoriesRepository';
import StaffRepository from '@/repositories/StaffRepository';
import ContentRepository from '@/repositories/ContentRepository';
import BannersRepository from '@/repositories/BannersRepository';
import DashboardRepository from '@/repositories/DashboardRepository';
import PollsRepository from '@/repositories/PollsRepository';
import TypesRepository from '@/repositories/TypesRepository';
import NewsRepository from '@/repositories/NewsRepository';
import GalleriesRepository from '@/repositories/GalleriesRepository';
import PagesRepository from '@/repositories/PagesRepository';
import SocialsRepository from '@/repositories/SocialsRepository';
import ContributionsRepository from '@/repositories/ContributionsRepository';
import PlansRepository from '@/repositories/PlansRepository';
import CustomersRepository from '@/repositories/CustomersRepository';
import BrandsRepository from '@/repositories/BrandsRepository';

export const USERS = 'users';
export const CATEGORIES = 'categories';
export const STAFF = 'staff';
export const BANNERS = 'banners';
export const DASHBOARD = 'dashboard';
export const POLLS = 'polls';
export const TYPES = 'types';
export const NEWS = 'news';
export const GALLERIES = 'galleries';
export const PAGES = 'pages';
export const SOCIALS = 'socials';
export const CONTRIBUTIONS = 'contributions';
export const CONTENT = 'content';
export const PLANS = 'plans';
export const CUSTOMERS = 'customers';
export const BRANDS = 'brands';

const repositories = {
  [CATEGORIES]: CategoriesRepository,
  [USERS]: UsersRepository,
  [STAFF]: StaffRepository,
  [BANNERS]: BannersRepository,
  [DASHBOARD]: DashboardRepository,
  [POLLS]: PollsRepository,
  [TYPES]: TypesRepository,
  [NEWS]: NewsRepository,
  [GALLERIES]: GalleriesRepository,
  [PAGES]: PagesRepository,
  [SOCIALS]: SocialsRepository,
  [CONTRIBUTIONS]: ContributionsRepository,
  [CONTENT]: ContentRepository,
  [PLANS]: PlansRepository,
  [CUSTOMERS]: CustomersRepository,
  [BRANDS]: BrandsRepository,
};

export default {
  get: name => repositories[name],
};
