import { find } from 'lodash';
import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_REQUEST_SUCCESS,
  CUSTOMER_LIST_REQUEST_ERROR,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_ERROR,
} from '@/store/actions/customer';
import { handleRequestError } from '@/utils/error';
import Repository, { CUSTOMERS } from '@/repositories/RepositoryFactory';

const CustomersRepository = Repository.get(CUSTOMERS);

const state = {
  status: '',
  customer: {},
  customerList: [],
  customerListMeta: {},
  customerListStatus: '',
};

const getters = {
  customerStatus: state => state.status,
  customerList: state => state.customerList,
  customerListMeta: state => state.customerListMeta,
  customerListStatus: state => state.customerListStatus,
  getCustomerById: (state, getters) => id => {
    if (getters.customerList.length > 0) {
      return find(getters.customerList, { id });
    }
    return state.staff;
  },
};

const actions = {
  [CUSTOMER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CUSTOMER_LIST_REQUEST);
    try {
      const response = await CustomersRepository.get(payload);
      commit(CUSTOMER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CUSTOMER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CUSTOMER_UPDATE]: async ({ commit }, { id, data }) => {
    commit(CUSTOMER_UPDATE);
    try {
      await CustomersRepository.update(id, data);
      commit(CUSTOMER_UPDATE_SUCCESS);
    } catch (error) {
      commit(CUSTOMER_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [CUSTOMER_DELETE]: async ({ commit }, id) => {
    commit(CUSTOMER_DELETE);
    try {
      await CustomersRepository.delete(id);
      commit(CUSTOMER_DELETE_SUCCESS);
    } catch (error) {
      commit(CUSTOMER_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};
const mutations = {
  [CUSTOMER_LIST_REQUEST]: state => {
    state.customerListStatus = 'loading';
  },
  [CUSTOMER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.customerListStatus = 'success';
    state.customerList = payload.data;

    if (payload.meta) {
      state.customerListMeta = payload.meta;
    }
  },
  [CUSTOMER_LIST_REQUEST_ERROR]: state => {
    state.customerListStatus = 'error';
  },
  [CUSTOMER_UPDATE]: state => {
    state.status = 'loading';
  },
  [CUSTOMER_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CUSTOMER_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [CUSTOMER_DELETE]: state => {
    state.status = 'deleting';
  },
  [CUSTOMER_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [CUSTOMER_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
