import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import file from '@/store/modules/file';
import resetPassword from '@/store/modules/resetPassword';
import user from '@/store/modules/user';
import category from '@/store/modules/category';
import staff from '@/store/modules/staff';
import content from '@/store/modules/content';
import banner from '@/store/modules/banner';
import dashboard from '@/store/modules/dashboard';
import poll from '@/store/modules/poll';
import type from '@/store/modules/type';
import news from '@/store/modules/news';
import gallery from '@/store/modules/gallery';
import page from '@/store/modules/page';
import social from '@/store/modules/social';
import contribution from '@/store/modules/contribution';
import plan from '@/store/modules/plan';
import customer from '@/store/modules/customer';
import brand from '@/store/modules/brand';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    file,
    resetPassword,
    user,
    category,
    staff,
    content,
    banner,
    dashboard,
    poll,
    type,
    news,
    gallery,
    page,
    social,
    contribution,
    plan,
    customer,
    brand,
  },
  strict: debug,
});
