<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>

      <InputEditor
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="$v.form.description.$model"
        :vuelidate="$v.form.description"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { NEWS_CREATE, NEWS_UPDATE } from '@/store/actions/news';
import { FILE_UPLOAD } from '@/store/actions/file';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputEditor from '@/components/ui/input/InputEditor';

const defaultValuesForForm = () => ({
  name: '',
  file: null,
  description: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      file: {
        required,
      },
      description: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['newsStatus', 'getFileUpload', 'fileDeleteStatus']),
    isLoading() {
      return this.newsStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('news.newNews') : this.$t('news.editNews');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            file: this.formData.file,
            description: this.formData.description,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onShown() {
      // this.$store.dispatch(NEWS_POSITIONS_REQUEST);
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = { ...this.form, fileId: this.form.file ? this.form.file.id : null };

      if (isNil(this.formData)) {
        await this.$store.dispatch(NEWS_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(NEWS_UPDATE, payload);
      }
      if (this.newsStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.file.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.file = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputPhoto,
    InputLabel,
    InputEditor,
  },
};
</script>
