<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="true"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="title"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.title.$model"
        :vuelidate="$v.form.title"
      />
      <InputText
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="$v.form.description.$model"
        :vuelidate="$v.form.description"
      />

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputLabel>Баннер</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.banner"
            :vuelidate="$v.form.banner"
            @change="onChangeBanner"
            @remove="onDeleteBanner"
          />
        </b-col>
      </b-row>

      <InputText
        class="address"
        :label="$t('label.address')"
        size="lg"
        v-model="$v.form.address.$model"
        :vuelidate="$v.form.address"
      />
      <InputString
        class="phone"
        :label="$t('label.phone')"
        size="lg"
        v-model="$v.form.phone.$model"
        :vuelidate="$v.form.phone"
      />
      <InputString
        class="email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <InputString
        class="facebook"
        :label="$t('label.facebook')"
        size="lg"
        v-model="$v.form.facebook.$model"
        :vuelidate="$v.form.facebook"
      />
      <InputString
        class="youtube"
        :label="$t('label.youtube')"
        size="lg"
        v-model="$v.form.youtube.$model"
        :vuelidate="$v.form.youtube"
      />
      <InputString
        class="instagram"
        :label="$t('label.instagram')"
        size="lg"
        v-model="$v.form.instagram.$model"
        :vuelidate="$v.form.instagram"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { PAGE_CREATE, PAGE_UPDATE } from '@/store/actions/page';
import { FILE_UPLOAD } from '@/store/actions/file';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputText from '@/components/ui/input/InputText';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';

const defaultValuesForForm = () => ({
  title: '',
  description: '',
  address: '',
  phone: '',
  email: '',
  facebook: '',
  youtube: '',
  instagram: '',
  file: null,
  banner: null,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
      address: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
      },
      facebook: {
        required,
      },
      youtube: {
        required,
      },
      instagram: {
        required,
      },
      file: {
        required,
      },
      banner: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['pageStatus', 'getFileUpload', 'fileDeleteStatus']),
    isLoading() {
      return this.pageStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('page.newPage') : this.$t('page.editPage');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            title: this.formData.title,
            description: this.formData.description,
            address: this.formData.address,
            phone: this.formData.phone,
            email: this.formData.email,
            facebook: this.formData.facebook,
            youtube: this.formData.youtube,
            instagram: this.formData.instagram,
            file: this.formData.image,
            banner: this.formData.banner,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = {
        ...this.form,
        imageId: this.form.file ? this.form.file.id : null,
        bannerId: this.form.banner ? this.form.banner.id : null,
      };

      if (isNil(this.formData)) {
        await this.$store.dispatch(PAGE_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(PAGE_UPDATE, payload);
      }
      if (this.pageStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.file.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.file = null;
      // }
    },
    async onChangeBanner(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.banner = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeleteBanner() {
      // await this.$store.dispatch(FILE_DELETE, this.form.banner.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.banner = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputText,
    InputPhoto,
    InputLabel,
  },
};
</script>
