export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST';
export const BRAND_LIST_REQUEST_SUCCESS = 'BRAND_LIST_REQUEST_SUCCESS';
export const BRAND_LIST_REQUEST_ERROR = 'BRAND_LIST_REQUEST_ERROR';

export const BRAND_CREATE = 'BRAND_CREATE';
export const BRAND_CREATE_SUCCESS = 'BRAND_CREATE_SUCCESS';
export const BRAND_CREATE_ERROR = 'BRAND_CREATE_ERROR';

export const BRAND_UPDATE = 'BRAND_UPDATE';
export const BRAND_UPDATE_SUCCESS = 'BRAND_UPDATE_SUCCESS';
export const BRAND_UPDATE_ERROR = 'BRAND_UPDATE_ERROR';

export const BRAND_DELETE = 'BRAND_DELETE';
export const BRAND_DELETE_SUCCESS = 'BRAND_DELETE_SUCCESS';
export const BRAND_DELETE_ERROR = 'BRAND_DELETE_ERROR';
