<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSave">
      <InputSelect
        v-if="isParts"
        class="category"
        :label="$t('label.category')"
        size="lg"
        v-model="$v.form.categoryId.$model"
        :vuelidate="$v.form.categoryId"
        :options="categoryList"
        :disabled="isLoadingCategoryList"
      />
      <InputSelect
        class="brand"
        :label="$t('label.brand')"
        size="lg"
        v-model="$v.form.brandId.$model"
        :vuelidate="$v.form.brandId"
        :options="brandList"
        :disabled="isLoadingBrandList"
      />

      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>

      <InputRadioButton
        v-if="!isParts"
        class="status"
        label="Түрээслэх"
        size="lg"
        v-model="form.isRent"
        :options="statusOptions"
      />

      <InputLabel>Үзүүлэлт</InputLabel>

      <div
        v-for="(option, index) in $v.form.options.$each.$iter"
        :key="`option-${index}`"
        class="mb-3"
      >
        <InputString
          class="name mb-0"
          placeholder="Нэршил"
          size="md"
          v-model="option.keyName.$model"
          :vuelidate="option.keyName"
        />
        <InputText
          class="value mb-0"
          placeholder="Утга"
          size="md"
          v-model="option.value.$model"
          :vuelidate="option.value"
        />
      </div>

      <InlineButton size="sm" @click="addOption">Үзүүлэлт нэмэх</InlineButton>
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { CONTENT_CREATE, CONTENT_UPDATE } from '@/store/actions/content';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/category';
import { BRAND_LIST_REQUEST } from '@/store/actions/brand';
import { FILE_UPLOAD } from '@/store/actions/file';
import { YES_OR_NO_OPTIONS } from '@/utils/constants';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputText from '@/components/ui/input/InputText';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InlineButton from '@/components/ui/button/InlineButton';

const defaultValuesForForm = () => ({
  categoryId: null,
  name: '',
  file: null,
  brandId: null,
  options: [],
  isRent: false,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    isParts: Boolean,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: YES_OR_NO_OPTIONS,
    };
  },

  validations() {
    if (this.isParts) {
      return {
        form: {
          categoryId: {
            required,
          },
          brandId: {
            required,
          },
          name: {
            required,
          },
          file: {
            required,
          },
          options: {
            required,
            $each: {
              keyName: { required },
              value: { required },
            },
          },
        },
      };
    }

    return {
      form: {
        brandId: {
          required,
        },
        name: {
          required,
        },
        file: {
          required,
        },
        options: {
          required,
          $each: {
            keyName: { required },
            value: { required },
          },
        },
      },
    };
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters([
      'contentStatus',
      'getFileUpload',
      'categoryList',
      'categoryListStatus',
      'fileDeleteStatus',
      'brandList',
      'brandListStatus',
    ]),
    isLoading() {
      return this.contentStatus === 'loading';
    },
    isLoadingCategoryList() {
      return this.categoryListStatus === 'loading';
    },
    isLoadingBrandList() {
      return this.brandListStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? 'Бүтээгдэхүүн нэмэх' : 'Бүтээгдэхүүн засах';
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    addOption() {
      this.form.options.push({ keyName: '', value: '' });
    },
    updateForm() {
      this.form = this.formData
        ? {
            categoryId: this.formData.categoryId,
            name: this.formData.name,
            file: this.formData.file,
            brandId: this.formData.brandId,
            options: this.formData.options,
            isRent: !!this.formData.isRent,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onShown() {
      const payload = {
        limit: 100,
        page: 1,
        filter: {},
      };
      this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
      this.$store.dispatch(BRAND_LIST_REQUEST, payload);
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = {
        ...this.form,
        fileId: this.form.file ? this.form.file.id : null,
        type: this.isParts ? 'PART' : 'PRODUCT',
      };

      if (isNil(this.formData)) {
        await this.$store.dispatch(CONTENT_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(CONTENT_UPDATE, payload);
      }
      if (this.contentStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.file.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.file = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
    InputPhoto,
    InputLabel,
    InputRadioButton,
    InlineButton,
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: -0.25rem;

  & > div {
    padding: 0.25rem;
  }
}
</style>
