<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <ContentTable
      :list="contentList"
      :meta="contentListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <ContentAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONTENT_LIST_REQUEST, CONTENT_DELETE } from '@/store/actions/content';
import { KEY_FILTER_TYPE } from '@/utils/filter';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import ContentTable from '@/components/manage/content/ContentTable';
import ContentAddEditModal from '@/components/manage/content/ContentAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters([
      'contentList',
      'contentListMeta',
      'contentListStatus',
      'getContentById',
      'contentStatus',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getContentById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.contentListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const payload = {
        limit: 15,
        page: this.currentPage,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_TYPE}`] = 'PRODUCT';
      this.$store.dispatch(CONTENT_LIST_REQUEST, payload);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(CONTENT_DELETE, this.dataIdToRemove);
      if (this.contentStatus === 'deleted') {
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
    onHidden() {
      this.emptyingDataToEdit();
      this.fetchData();
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    ContentTable,
    ContentAddEditModal,
    ConfirmDialog,
  },
};
</script>
