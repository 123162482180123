<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :empty-text="$t('customers.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ meta && meta.perPage ? (currentPage - 1) * meta.perPage + data.index + 1 : '' }}
      </template>

      <!-- <template #cell(phone)="data">
        {{ data.item.phone }}
      </template> -->

      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{
            data.item.createdAt ? $options.filters.moment(data.item.createdAt, 'YYYY-MM-DD') : ''
          }}
        </span>
      </template>

      <template #cell(details)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.edit') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>
<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    isLoading: Boolean,
    meta: Object,
    currentPage: Number,
    list: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
          tdClass: 'td-index',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        // {
        //   key: 'phone',
        //   label: this.$t('table.th.phoneNumber'),
        // },
        {
          key: 'email',
          label: this.$t('table.th.email'),
        },
        {
          key: 'createdAt',
          label: this.$t('table.th.registered'),
        },
        // {
        //   key: 'planId',
        //   label: this.$t('table.th.membership'),
        // },
        {
          key: 'endsAt',
          label: this.$t('table.th.endsAt'),
        },
        {
          key: 'details',
          label: '',
        },
      ],
    };
  },
  components: {
    TableBox,
    InlineButton,
  },
};
</script>
